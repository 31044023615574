import {defineStore} from "pinia"
import {useCaseMembersStore} from "@/library/stores/case-member"
import useCan from "@/library/composables/useCan"
import {CaseAbilities} from "@/library/models/case.interface"
import {useCaseStore} from "@/library/stores/case"

export const usePermissionStore = defineStore("permissions", {
  state: () => ({}),
  getters: {
    canAccessRoadmap() {
      const caseMemberStore = useCaseMembersStore()

      return caseMemberStore.isAuthMemberAnOwner || caseMemberStore.isAuthMemberACollaborator
    },
    canAccessQuestionnaire() {
      const caseMemberStore = useCaseMembersStore()

      return caseMemberStore.isAuthMemberAnOwner || caseMemberStore.isAuthMemberACollaborator
    },
    canAccessDigitalVault() {
      const caseMemberStore = useCaseMembersStore()

      return (
        caseMemberStore.isAuthMemberAnOwner ||
        caseMemberStore.isAuthMemberACollaborator ||
        caseMemberStore.isAuthMemberASuccessor
      )
    },

    isDigitalVaultMutable() {
      return useCan(CaseAbilities.ACCESS_PERSONAL_DATA, useCaseStore().activeCase)
    },

    isDigitalVaultReadonly() {
      return !this.isDigitalVaultMutable
    },

    canDownload() {
      const caseMemberStore = useCaseMembersStore()

      return caseMemberStore.isAuthMemberAnOwner || caseMemberStore.isAuthMemberACollaborator
    },
  },
  actions: {},
})
