import {type IAppFieldPathSet} from "@/library/domain/app-fields/key-sets"
import {SectionTypes} from "@/library/domain/app-fields/key-sets/sections"

export const DECEASED_PERSONAL_INFO_PATHSET: IAppFieldPathSet = {
  id: SectionTypes.DECEASED_PERSONAL_INFO,
  paths: [
    "deceased.name",
    "deceased.gender",
    "deceased.maiden_name",
    "deceased.name_at_birth",
    "deceased.date_of_birth",
    "deceased.place_of_birth",
    "deceased.ethnicity",
    "deceased.marital_status",
  ],
}

export const DECEASED_CONTACT_INFO_PATHSET: IAppFieldPathSet = {
  id: SectionTypes.DECEASED_CONTACT_INFO,
  paths: [
    "deceased.phone[*].phone_number",
    "deceased.email[*].email_address",
    "deceased.home_address",
    "deceased.mailing_address",
    "deceased.is_mailing_address_same_as_home_address",
  ],
}

export const DECEASED_IMPORTANT_DOCS_PATHSET: IAppFieldPathSet = {
  id: SectionTypes.DECEASED_IMPORTANT_DOCS,
  paths: [
    "deceased.birth_certificate_location_hint",
    "deceased.citizenship_status",
    "deceased.social_insurance_number",
    "deceased.health_care_number",
    "deceased.military.discharge_document",
  ],
}

export const DECEASED_PARENT_INFO_PATHSET: IAppFieldPathSet = {
  id: SectionTypes.DECEASED_PARENT_INFO,
  paths: [
    "deceased.mothers_name",
    "deceased.mothers_maiden_name",

    "deceased.fathers_name",
    "deceased.fathers_family_name",
  ],
}

// @note - these isolated to and are flagged `irrelevantPathsForCaseState`
// See: `stores/mapped-app-field-key::isValidFormFillerPath(...)`
export const DECEASED_END_OF_LIFE_INFO_PATHSET: IAppFieldPathSet = {
  id: SectionTypes.DECEASED_END_OF_LIFE_INFO,
  paths: [
    "deceased.date_of_death",
    "deceased.place_of_death",
    "deceased.cause_of_death",
    "deceased.proof_of_death",
    // "funeral.funeral_director", // todo: doesn't exist
  ],
}
