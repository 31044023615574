import {
  type IRepeatableAppFieldPathSets,
  type IRepeatableAppFieldPathSetsWithConstraints,
} from "@/library/domain/app-fields/key-sets"
import {SectionTypes} from "@/library/domain/app-fields/key-sets/sections"

export const UTILITIES_PATH = "utility"

export const UTILITIES_PATHSET: IRepeatableAppFieldPathSets = {
  id: SectionTypes.UTILITIES,
  repeatable_by: "utility[*]",
  paths: [
    "utility[*].type",
    "utility[*].name",
    "utility[*].expiry_date",
    "utility[*].id",
    "utility[*].attachment",
    "utility[*].notes",
  ],
}

export const FILTER_UTILITIES__ELECTRICITY_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_UTILITY__electricity,
  repeatable_by: "utility__electricity[*]",
  constrained_by: null,
  source: UTILITIES_PATHSET,
}
export const FILTER_UTILITIES__PROPERTY_TAXES_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_UTILITY__property_taxes,
  repeatable_by: "utility__property_taxes[*]",
  constrained_by: null,
  source: UTILITIES_PATHSET,
}
export const FILTER_UTILITIES__GAS_HYDRO_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_UTILITY__gas_hydro,
  repeatable_by: "utility__gas_hydro[*]",
  constrained_by: null,
  source: UTILITIES_PATHSET,
}
export const FILTER_UTILITIES__WATER_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_UTILITY__water,
  repeatable_by: "utility__water[*]",
  constrained_by: null,
  source: UTILITIES_PATHSET,
}
export const FILTER_UTILITIES__INTERNET_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_UTILITY__internet,
  repeatable_by: "utility__internet[*]",
  constrained_by: null,
  source: UTILITIES_PATHSET,
}
export const FILTER_UTILITIES__PHONE_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_UTILITY__phone,
  repeatable_by: "utility__phone[*]",
  constrained_by: null,
  source: UTILITIES_PATHSET,
}
