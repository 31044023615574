import {type App, watch} from "vue"
import {createLogger} from "@/library/domain/logger"
import {type IMetricsDataPointContext, useMetricsDataPointStore} from "@/library/stores/metrics-data-point"
import router from "@/router"
import {each} from "lodash"

export default async function setupClarity(app: App) {
  if (!import.meta.env.VITE_CLARITY_API_KEY) {
    createLogger().info(
      "@/client/enduser/src/setup/setupClarity.ts",
      `Skipping initialization of Clarity due to absent VITE_CLARITY_API_KEY`,
    )
    return
  }

  const metricsDataPointStore = useMetricsDataPointStore()
  watch(
    () => metricsDataPointStore.context,
    (point: IMetricsDataPointContext) => {
      if (!isLoaded()) {
        return
      }

      setContext(point, clarity)
    },
    {flush: "sync"},
  )

  await metricsDataPointStore.isLoaded()
  const clarity = fetchLibrary()
  setContext(metricsDataPointStore.context, clarity) // todo: this needs some love; we're still not likely to have everything set on post-auth route change

  router.afterEach(async to => {
    clarity("set", "pageview", {
      url: to.path.replace(/^\/cases\/\d+/, "/cases/*"),
    })
  })
}

function fetchLibrary() {
  // @ts-ignore
  // eslint-disable-next-line no-extra-semi
  // prettier-ignore
  ;(function(c,l,a,r,i,t,y){c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);})(window, document, "clarity", "script", import.meta.env.VITE_CLARITY_API_KEY);

  // @ts-ignore
  return window.clarity
}

function isLoaded() {
  return "clarity" in window
}

function setContext(context: IMetricsDataPointContext, clarity: (...args: any) => void) {
  each(context, (v, k) => clarity("set", k, v))
}
