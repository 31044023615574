import {defineStore} from "pinia"
import axios from "axios"
import type {ICaseMember, ICollaborator} from "@/library/models/case-member.interface"
import {chain, filter, find, keyBy} from "lodash"
import type {ICase} from "@/library/models/case.interface"
import {useApiNotificationsStore} from "@/library/stores/api-notifications"
import type {IBaseCollectionStoreState} from "@/library/stores/_base-collection"
import {
  baseCollectionStoreActions,
  baseCollectionStoreGetters,
  baseCollectionStoreState,
} from "@/library/stores/_base-collection"
import {useUserStore} from "@/library/stores/user"
import {
  COLLABORATOR_ROLE,
  NON_CONTRIBUTOR_ROLE,
  ORIGINAL_OWNER_ROLE,
  OWNER_ROLE,
  SUCCESSOR_ROLE,
} from "@/library/constants/case-member"
import {CaseCollaborationRequestStatuses} from "@/library/models/case-collaboration-request.interface"

interface ICaseMemberStoreState extends IBaseCollectionStoreState<ICaseMember> {
  trashedItems: null | ICaseMember[]
  successor?: boolean
}

export const useCaseMembersStore = defineStore("case-members", {
  state: (): ICaseMemberStoreState => ({
    ...baseCollectionStoreState<ICaseMember>(),
    trashedItems: null,
  }),

  getters: {
    ...baseCollectionStoreGetters<ICaseMember>(),
    loaded(state): boolean {
      return state.items !== null
    },
    successorMembers(): ICaseMember[] {
      return filter(this.members || [], ({role}) => role === SUCCESSOR_ROLE)
    },
    membersById({items}): Record<ICaseMember["id"], ICaseMember> {
      return keyBy(items, "id")
    },
    members({items}): ICaseMember[] | null {
      return items
    },
    nonSuccessorMembers(): ICaseMember[] {
      return filter(this.members || [], ({role}) => role !== SUCCESSOR_ROLE)
    },
    nonOGOwnerMembers(): ICaseMember[] {
      return filter(this.members || [], ({role}) => role !== ORIGINAL_OWNER_ROLE)
    },
    ownerOrCollaboMembers(): ICaseMember[] {
      return filter(
        this.members || [],
        caseMember =>
          caseMember.role === OWNER_ROLE ||
          caseMember.role === COLLABORATOR_ROLE ||
          caseMember.meta.is_collaborator === true,
      )
    },
    authUserMember(state): ICaseMember | null {
      return find(state.items, {user_id: useUserStore().user?.id}) || null
    },
    isAuthMemberAnOwner(): boolean {
      return this.authUserMember?.role === OWNER_ROLE
    },
    isAuthMemberACollaborator(): boolean {
      return this.authUserMember?.role === COLLABORATOR_ROLE || this.isAuthMemberASuccessorCollaborator
    },
    isAuthMemberASuccessor(): boolean {
      return this.authUserMember?.role === SUCCESSOR_ROLE
    },
    isAuthMemberASuccessorLimitedAccess(): boolean {
      return this.isAuthMemberASuccessor && !this.authUserMember?.meta.is_collaborator
    },
    isAuthMemberASuccessorCollaborator(): boolean {
      return this.isAuthMemberASuccessor && !!this.authUserMember?.meta.is_collaborator
    },
    isAuthMemberANonContributor(): boolean {
      return this.authUserMember?.role === NON_CONTRIBUTOR_ROLE
    },
    isAuthMemberABusinessCollaborator(): boolean {
      const authedMember = this.authUserMember
      const isBusinessCollaborator =
        authedMember?.case_collaboration_request?.status === CaseCollaborationRequestStatuses.ACCEPTED
      const isBusinessCollaboratorAccessingBeforeOwner = !!(
        !authedMember?.case_collaboration_request &&
        !authedMember?.case_invitation &&
        authedMember?.user_id
      )

      return this.isAuthMemberACollaborator && (isBusinessCollaborator || isBusinessCollaboratorAccessingBeforeOwner)
    },
    isAuthMemberOwnerOrCollaborator(): boolean {
      if (!this.authUserMember) {
        return false
      }

      return this.ownerOrCollaboMembers.includes(this.authUserMember)
    },
    owner({items}): ICaseMember {
      return find(items, {role: OWNER_ROLE})!
    },
    collaborators({items}): ICaseMember[] {
      return filter(items, {role: COLLABORATOR_ROLE})
    },
    successorCollaborators({items}): ICaseMember[] {
      return filter(items, {meta: {is_collaborator: true}, role: SUCCESSOR_ROLE})
    },
    nonContributors({items}): ICaseMember[] {
      return filter(items, {role: NON_CONTRIBUTOR_ROLE})
    },
  },
  actions: {
    ...baseCollectionStoreActions<ICaseMember>(),
    async fetchMembers(caseId: ICase["id"]): Promise<null | ICaseMember[]> {
      return this.cachedFetch(async () => {
        const response = await axios.get<{data: ICaseMember[]}>(`/v3/enduser/cases/${caseId}/members`)
        const items = response.data.data
        this._syncCollaborationRequestsToNotificationsFor(items)
        this._syncCaseMembersToNotificationsFor(items)
        return items
      })
    },

    async fetchTrashedMembers(caseId: ICase["id"]): Promise<ICaseMember[]> {
      if (this.trashedItems) {
        return this.trashedItems
      }
      const response = await axios.get<{
        data: ICaseMember[]
      }>(`/v3/enduser/cases/${caseId}/members`, {params: {deleted: true}})
      this.trashedItems = response.data.data
      return this.trashedItems
    },

    async createMember(caseId: ICase["id"], member: Partial<ICaseMember>, password?: string) {
      const response = await axios.post<{data: ICaseMember}>(`/v3/enduser/cases/${caseId}/members`, {
        ...member,
        password,
      })
      this.upsert(response.data.data)
    },

    async bulkCreateMembers(caseId: ICase["id"], members: {invitations: ICollaborator[]}, password?: string) {
      const response = await axios.post<{data: ICaseMember[]}>(`/v3/enduser/cases/${caseId}/add-collaborators`, {
        ...members,
        password,
      })
      for (const member of response.data.data) {
        this.upsert(member)
      }
    },

    async delete(caseId: ICase["id"], id: ICaseMember["id"]) {
      await axios.delete(`/v3/enduser/cases/${caseId}/members/${id}`)
      this.remove(id)
      this.$patch({trashedItems: null}) // note: setting directly to null broke reactivity
    },

    _syncCollaborationRequestsToNotificationsFor(members: ICaseMember[]) {
      const knownCollaboRequestsById = chain(members)
        .map("case_collaboration_request")
        .filter() // strip empties
        .keyBy("id")
        .value()

      useApiNotificationsStore().items?.forEach(({data}) => {
        if (!data?.case_collaboration_request) {
          // instanceof NOTIFICATION_ORG_COLLABORATOR_REQUESTED
          return
        }

        data.case_collaboration_request =
          data.case_collaboration_request.id in knownCollaboRequestsById
            ? knownCollaboRequestsById[data.case_collaboration_request.id] // sync ref
            : data.case_collaboration_request // leave as is
      })

      return members
    },

    _syncCaseMembersToNotificationsFor(members: ICaseMember[]) {
      useApiNotificationsStore().items?.forEach(({data}) => {
        if (!data?.case_member) {
          // instanceof NOTIFICATION_ORG_COLLABORATOR_EXISTS
          return
        }

        data.case_member =
          data.case_member.id in this.membersById
            ? this.membersById[data.case_member.id] // sync ref
            : data.case_member // leave as is
      })

      return members
    },

    formatCaseMemberDisplayName(member: ICaseMember) {
      if (!member) {
        return
      }

      if (!member.first_name) {
        return member.email
      }

      return `${member.first_name} ${member.last_name?.charAt(0)}.`
    },

    async subscribeGriefCareNewsletter(member: ICaseMember) {
      const response = await axios.post(
        `/v3/enduser/cases/${member.case_id}/members/${member.id}/grief-emails-subscription`,
      )

      this.upsert(response.data.data)
    },

    async unsubscribeGriefCareNewsletter(member: ICaseMember) {
      const response = await axios.delete(
        `/v3/enduser/cases/${member.case_id}/members/${member.id}/grief-emails-subscription`,
      )

      this.upsert(response.data.data)
    },
  },
})
