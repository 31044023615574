import {
  type IRepeatableAppFieldPathSets,
  type IRepeatableAppFieldPathSetsWithConstraints,
} from "@/library/domain/app-fields/key-sets"
import {SectionTypes} from "@/library/domain/app-fields/key-sets/sections"

export const ID_DOCUMENT_PATH = "id_document"

export const ID_DOCUMENT_PATHSET: IRepeatableAppFieldPathSets = {
  id: SectionTypes.ID_DOCUMENTS,
  repeatable_by: "id_document[*]",
  paths: [
    "id_document[*].type",
    "id_document[*].name",
    "id_document[*].expiry_date",
    "id_document[*].id",
    "id_document[*].attachment",
    "id_document[*].notes",
  ],
}

export const FILTER_ID_DOCUMENT__accessible_parking_permit: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_ID_DOCUMENT__accessible_parking_permit,
  repeatable_by: "id_document__accessible_parking_permit[*]",
  constrained_by: null,
  source: ID_DOCUMENT_PATHSET,
}
export const FILTER_ID_DOCUMENT__drivers_license: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_ID_DOCUMENT__drivers_license,
  repeatable_by: "id_document__drivers_license[*]",
  constrained_by: null,
  source: ID_DOCUMENT_PATHSET,
}
export const FILTER_ID_DOCUMENT__firearm_license: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_ID_DOCUMENT__firearm_license,
  repeatable_by: "id_document__firearm_license[*]",
  constrained_by: null,
  source: ID_DOCUMENT_PATHSET,
}
export const FILTER_ID_DOCUMENT__health_card: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_ID_DOCUMENT__health_card,
  repeatable_by: "id_document__health_card[*]",
  constrained_by: null,
  source: ID_DOCUMENT_PATHSET,
}
export const FILTER_ID_DOCUMENT__nexus: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_ID_DOCUMENT__nexus,
  repeatable_by: "id_document__nexus[*]",
  constrained_by: null,
  source: ID_DOCUMENT_PATHSET,
}
export const FILTER_ID_DOCUMENT__passport: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_ID_DOCUMENT__passport,
  repeatable_by: "id_document__passport[*]",
  constrained_by: null,
  source: ID_DOCUMENT_PATHSET,
}
