import {
  type IRepeatableAppFieldPathSets,
  type IRepeatableAppFieldPathSetsWithConstraints,
} from "@/library/domain/app-fields/key-sets"
import {SectionTypes} from "@/library/domain/app-fields/key-sets/sections"

export const INSURANCE_PATH = "insurance"

export const INSURANCE_PATHSET: IRepeatableAppFieldPathSets = {
  id: SectionTypes.INSURANCE,
  repeatable_by: "insurance[*]",
  paths: [
    "insurance[*].type",
    "insurance[*].name",
    "insurance[*].expiry_date",
    "insurance[*].id",
    "insurance[*].attachment",
    "insurance[*].notes",
  ],
}

export const INSURANCE_FILTER_CONTAINER_NAMES = [
  "insurance__life__northwestern_mutual",
  "insurance__life__massmutual",
  "insurance__life__new_york_life",
  "insurance__life__prudential",
  "insurance__life__lincoln_financial",
  "insurance__life__john_hancock",
  "insurance__life__pacific_life",
  "insurance__life__corebridge_financial",
  "insurance__life__midland_national",
  "insurance__life__nationwide",
  "insurance__life__pennmutual",
  "insurance__life__equitable",
  "insurance__life__national_life",
  "insurance__life__manulife",
  "insurance__life__industrial_alliance",
  "insurance__life__bmo",
  "insurance__life__ivari",
]

export const FILTER_INSURANCE__LIFE__NORTHWESTERN_MUTUAL_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_INSURANCE__life__northwestern_mutual,
  repeatable_by: "insurance__life__northwestern_mutual[*]",
  constrained_by: [{values: {type: "life", name: "northwestern_mutual"}}], // constraints is no-op for now
  source: INSURANCE_PATHSET,
}

export const FILTER_INSURANCE__LIFE__MASSMUTUAL_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_INSURANCE__life__massmutual,
  repeatable_by: "insurance__life__massmutual[*]",
  constrained_by: null,
  source: INSURANCE_PATHSET,
}

export const FILTER_INSURANCE__LIFE__NEW_YORK_LIFE_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_INSURANCE__life__new_york_life,
  repeatable_by: "insurance__life__new_york_life[*]",
  constrained_by: null,
  source: INSURANCE_PATHSET,
}
export const FILTER_INSURANCE__LIFE__PRUDENTIAL_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_INSURANCE__life__prudential,
  repeatable_by: "insurance__life__prudential[*]",
  constrained_by: null,
  source: INSURANCE_PATHSET,
}
export const FILTER_INSURANCE__LIFE__LINCOLN_FINANCIAL_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_INSURANCE__life__lincoln_financial,
  repeatable_by: "insurance__life__lincoln_financial[*]",
  constrained_by: null,
  source: INSURANCE_PATHSET,
}
export const FILTER_INSURANCE__LIFE__JOHN_HANCOCK_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_INSURANCE__life__john_hancock,
  repeatable_by: "insurance__life__john_hancock[*]",
  constrained_by: null,
  source: INSURANCE_PATHSET,
}
export const FILTER_INSURANCE__LIFE__PACIFIC_LIFE_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_INSURANCE__life__pacific_life,
  repeatable_by: "insurance__life__pacific_life[*]",
  constrained_by: null,
  source: INSURANCE_PATHSET,
}
export const FILTER_INSURANCE__LIFE__COREBRIDGE_FINANCIAL_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_INSURANCE__life__corebridge_financial,
  repeatable_by: "insurance__life__corebridge_financial[*]",
  constrained_by: null,
  source: INSURANCE_PATHSET,
}
export const FILTER_INSURANCE__LIFE__MIDLAND_NATIONAL_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_INSURANCE__life__midland_national,
  repeatable_by: "insurance__life__midland_national[*]",
  constrained_by: null,
  source: INSURANCE_PATHSET,
}
export const FILTER_INSURANCE__LIFE__NATIONWIDE_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_INSURANCE__life__nationwide,
  repeatable_by: "insurance__life__nationwide[*]",
  constrained_by: null,
  source: INSURANCE_PATHSET,
}
export const FILTER_INSURANCE__LIFE__PENNMUTUAL_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_INSURANCE__life__pennmutual,
  repeatable_by: "insurance__life__pennmutual[*]",
  constrained_by: null,
  source: INSURANCE_PATHSET,
}
export const FILTER_INSURANCE__LIFE__EQUITABLE_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_INSURANCE__life__equitable,
  repeatable_by: "insurance__life__equitable[*]",
  constrained_by: null,
  source: INSURANCE_PATHSET,
}
export const FILTER_INSURANCE__LIFE__NATIONAL_LIFE_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_INSURANCE__life__national_life,
  repeatable_by: "insurance__life__national_life[*]",
  constrained_by: null,
  source: INSURANCE_PATHSET,
}
export const FILTER_INSURANCE__LIFE__MANULIFE_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_INSURANCE__life__manulife,
  repeatable_by: "insurance__life__manulife[*]",
  constrained_by: null,
  source: INSURANCE_PATHSET,
}
export const FILTER_INSURANCE__LIFE__INDUSTRIAL_ALLIANCE_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_INSURANCE__life__industrial_alliance,
  repeatable_by: "insurance__life__industrial_alliance[*]",
  constrained_by: null,
  source: INSURANCE_PATHSET,
}
export const FILTER_INSURANCE__LIFE__BMO_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_INSURANCE__life__bmo,
  repeatable_by: "insurance__life__bmo[*]",
  constrained_by: null,
  source: INSURANCE_PATHSET,
}
export const FILTER_INSURANCE__LIFE__IVARI_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_INSURANCE__life__ivari,
  repeatable_by: "insurance__life__ivari[*]",
  constrained_by: null,
  source: INSURANCE_PATHSET,
}

export const FILTER_INSURANCE__HEALTH_MEDICAL_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_INSURANCE__health_medical,
  repeatable_by: "insurance__health_medical[*]",
  constrained_by: null,
  source: INSURANCE_PATHSET,
}

export const FILTER_INSURANCE__HOME_PROPERTY_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_INSURANCE__home_property,
  repeatable_by: "insurance__home_property[*]",
  constrained_by: null,
  source: INSURANCE_PATHSET,
}

export const FILTER_INSURANCE__LIFE_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_INSURANCE__life,
  repeatable_by: "insurance__life[*]",
  constrained_by: null,
  source: INSURANCE_PATHSET,
}

export const FILTER_INSURANCE__VEHICLE_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_INSURANCE__vehicle,
  repeatable_by: "insurance__vehicle[*]",
  constrained_by: null,
  source: INSURANCE_PATHSET,
}
