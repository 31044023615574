import type {Abilities} from "@/library/models/abilities.interface"
import type {AuthUserAbilities} from "@/library/models/auth-user.interface"
import {useUserStore} from "@/library/stores/user"

type AuthorizationTarget = {__abilities?: Abilities} | {__abilities?: AuthUserAbilities} | string | null | undefined

/**
 * @param ability the action to check
 * @param target either
 *  1. an object instance of a data model (e.g. a user model):
 *    `useCan('update', user)`
 *  or,
 *  2. for abilities not related to a particular data model, a string
 *  identifying a data model type (e.g. 'location'):
 *    `useCan('create', 'user')`
 */
export default function useCan(ability: string, target: AuthorizationTarget): boolean {
  if (typeof target === "object") {
    const object = target as {__abilities?: Abilities}
    // checking an ability related to a particular data model
    if (!object["__abilities"]) {
      throw new Error(
        `Tried to check ability to '${ability}' on an object, but the object does not have the abilities field`,
      )
    }
    return !!object["__abilities"][ability]
  } else if (typeof target === "string") {
    // checking an ability not related to a particular data model
    const authUser = useUserStore().user
    const type = target
    if (!authUser) {
      throw new Error("Tried to check user ability but user is not loaded")
    }
    if (!authUser.__abilities) {
      throw new Error("Tried to check user ability but __abilities are not loaded on the user")
    }
    return !!authUser.__abilities[type]?.[ability]
  } else {
    throw new Error("target was empty")
  }
}
