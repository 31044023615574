import type {RouteLocation} from "vue-router"
import {
  EXECUTOR_ASSISTANT_ADDITIONAL_TOOLS_NAME,
  EXECUTOR_ASSISTANT_CONTACTS_NAME,
  EXECUTOR_ASSISTANT_DIGITAL_VAULT_SECTION_NAME,
  EXECUTOR_ASSISTANT_GRIEF_CARE_NAME,
  EXECUTOR_ASSISTANT_PLANNER_NAME,
  EXECUTOR_ASSISTANT_ROADMAP_NAME,
} from "@/executor-assistant/routes"
import {useCaseStore} from "@/library/stores/case"
import {find, startsWith} from "lodash"
import {CaseStatus} from "@/library/models/case.interface"
import useCaseTaskRouting from "@/library/composables/useCaseTaskRouting"
import {useCaseTaskStore} from "@/library/stores/case-task"
import {ROUTE_NAME_ACTIVE_CASE, ROUTE_NAME_NOT_FOUND} from '@/routes'
import {ensureCasesDataGuard} from "@/library/route-guards/ensureCasesDataGuard"

export const magicLinks = [
  {
    path: "/task/:taskId",
    beforeEnter: [ensureCasesDataGuard, redirectHomeIfRoadmapIsNotAvailable, taskMagicLinkBeforeEnter],
    component: () => import("@/library/components/routing/NotFound.vue"),
  },
  {
    path: "/estate-representatives",
    beforeEnter: [ensureCasesDataGuard, genericMagicLinkBeforeEnter],
    component: () => import("@/library/components/routing/NotFound.vue"),
  },
  {
    path: "/planner",
    beforeEnter: [ensureCasesDataGuard, genericMagicLinkBeforeEnter],
    component: () => import("@/library/components/routing/NotFound.vue"),
  },
  {
    path: "/roadmap",
    beforeEnter: [ensureCasesDataGuard, redirectHomeIfRoadmapIsNotAvailable, genericMagicLinkBeforeEnter],
    component: () => import("@/library/components/routing/NotFound.vue"),
  },
  {
    path: "/additional-tools",
    beforeEnter: [ensureCasesDataGuard, genericMagicLinkBeforeEnter],
    component: () => import("@/library/components/routing/NotFound.vue"),
  },
  {
    path: "/care",
    beforeEnter: [ensureCasesDataGuard, genericMagicLinkBeforeEnter],
    component: () => import("@/library/components/routing/NotFound.vue"),
  },
  {
    path: "/lp-task/:taskId",
    beforeEnter: [ensureCasesDataGuard, redirectHomeIfRoadmapIsNotAvailable, taskMagicLinkBeforeEnter],
    component: () => import("@/library/components/routing/NotFound.vue"),
  },
  {
    path: "/lp-vault/:section",
    beforeEnter: [ensureCasesDataGuard, vaultMagicLinksBeforeEnter],
    component: () => import("@/library/components/routing/NotFound.vue"),
  },
  {
    path: "/lp-roadmap",
    beforeEnter: [ensureCasesDataGuard, redirectHomeIfRoadmapIsNotAvailable, genericMagicLinkBeforeEnter],
    component: () => import("@/library/components/routing/NotFound.vue"),
  },
  {
    path: "/lp-planner",
    beforeEnter: [ensureCasesDataGuard, genericMagicLinkBeforeEnter],
    component: () => import("@/library/components/routing/NotFound.vue"),
  },
  {
    path: "/lp-contacts",
    beforeEnter: [ensureCasesDataGuard, genericMagicLinkBeforeEnter],
    component: () => import("@/library/components/routing/NotFound.vue"),
  },
]

async function taskMagicLinkBeforeEnter(to: RouteLocation, from: RouteLocation) {
  const caseId = await _findCaseIdMagicLink(to, from)
  if (!caseId) {
    return {name: ROUTE_NAME_NOT_FOUND}
  }
  await useCaseTaskStore().pullCaseTasks(caseId)

  return useCaseTaskRouting().createRouteForTaskId(+to.params["taskId"]) ?? {name: ROUTE_NAME_NOT_FOUND}
}

async function vaultMagicLinksBeforeEnter(to: RouteLocation, from: RouteLocation) {
  const caseId = await _findCaseIdMagicLink(to, from)
  if (!caseId) {
    return {name: ROUTE_NAME_NOT_FOUND}
  }

  return {name: EXECUTOR_ASSISTANT_DIGITAL_VAULT_SECTION_NAME, params: {caseId, section: to.params["section"]}}
}

async function genericMagicLinkBeforeEnter(to: RouteLocation, from: RouteLocation) {
  const caseId = await _findCaseIdMagicLink(to, from)
  if (!caseId) {
    return {name: ROUTE_NAME_NOT_FOUND}
  }
  switch (to.path) {
    case "/planner":
      return {name: EXECUTOR_ASSISTANT_PLANNER_NAME, params: {caseId}}
    case "/roadmap":
      return {name: EXECUTOR_ASSISTANT_ROADMAP_NAME, params: {caseId}}
    case "/additional-tools":
      return {name: EXECUTOR_ASSISTANT_ADDITIONAL_TOOLS_NAME, params: {caseId}}
    case "/care":
      return {name: EXECUTOR_ASSISTANT_GRIEF_CARE_NAME, params: {caseId}}

    // LP Routes
    case "/lp-roadmap":
      return {name: EXECUTOR_ASSISTANT_ROADMAP_NAME, params: {caseId}}
    case "/lp-planner":
      return {name: EXECUTOR_ASSISTANT_PLANNER_NAME, params: {caseId}}
    case "/lp-contacts":
      return {name: EXECUTOR_ASSISTANT_CONTACTS_NAME, params: {caseId}}
  }

  return {name: ROUTE_NAME_NOT_FOUND}
}
async function redirectHomeIfRoadmapIsNotAvailable(to: RouteLocation, from: RouteLocation) {
  // if the roadmap doesn't have tasks, that means the questionnaire has not yet been completed
  // this guard should be used before entering anything related to the roadmap e.g. a roadmap task, a learn task, etc.
  // the job of this guard is to redirect the user to the home page when the roadmap is not available
  const caseId = await _findCaseIdMagicLink(to, from)
  if (!caseId) {
    return {name: ROUTE_NAME_NOT_FOUND}
  }

  const caseTaskStore = useCaseTaskStore()
  await caseTaskStore.pullCaseTasks(caseId)
  if (! caseTaskStore.items?.length) {
    // if the roadmap doesn't have any tasks, redirect them to the root page for the case
    // since there is "Complete your questionnaire" prompt in this page.
    return {name: ROUTE_NAME_ACTIVE_CASE, params: {caseId}}
  }
}

async function _findCaseIdMagicLink(to: RouteLocation, from: RouteLocation) {
  if (from.params.caseId) {
    return +from.params.caseId
  }

  const caseStore = useCaseStore()
  if (caseStore.activeCase?.id) {
    return caseStore.activeCase.id
  }

  return startsWith(to.path, "/lp-")
    ? find(caseStore.items, {status: CaseStatus.STATUS_PRE_PLANNER})?.id
    : find(caseStore.items, {status: CaseStatus.STATUS_AFTER_CARE})?.id
}
