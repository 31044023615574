import {defineStore} from "pinia"
import type {IAppField} from "@/library/models/app-fields/app-field.interface"
import {
  chain,
  filter,
  find,
  flatMap,
  includes,
  intersection,
  isArray,
  mapValues,
  startsWith,
  transform,
  uniq,
  values,
} from "lodash"
import {
  bundleRepeatedKeysInKeySets,
  convertKeySignatureToPath,
  convertSignaturesToOrderedKeys,
  generateRepeatedKeySignaturesFor,
  groupAppFieldKeysIntoKeySets,
  type ICompositeAppFieldKeySet,
  mergeDuplicateKeySets,
  mergeListsOfKeys,
  PATHS_BY_SECTION_ID,
} from "@/library/domain/app-fields/key-sets"
import type {ICase} from "@/library/models/case.interface"
import type {ICaseTask} from "@/library/models/case-task.interface"
import axios from "axios"
import type {TAppFieldValueKey} from "@/library/stores/app-field-value"
import {createKeyFromSignature} from "@/library/stores/app-field-value"
import {useAppFieldStore} from "@/library/stores/app-field"
import {useCaseQuestionnaireSessionProgressStore} from "@/library/stores/case-questionnaire-session-progress"
import {DECEASED_END_OF_LIFE_INFO_PATHSET} from "@/library/domain/app-fields/key-set-registry/personal-information"
import {useCaseStore} from "@/library/stores/case"
import {SectionTypes} from "@/library/domain/app-fields/key-sets/sections"
import {useCaseMembersStore} from "@/library/stores/case-member"
import {WILL_ATTACHMENT_PATH} from "@/library/domain/app-fields/key-set-registry/will"

interface IState {
  _caseAgnosticKeySignaturesByIndexOfCaseTaskIdAndMappingType: Record<string, TAppFieldValueKey["signature"][]>
  isLoaded: boolean
}

interface IActions {
  pullForCaseWith(caseId: ICase["id"]): Promise<any>
}

export type ISignaturesByMappingType = Record<MappingTypes, TAppFieldValueKey["signature"][]>

export enum MappingTypes {
  app_field = "app_field",
  file_pdf = "file_pdf",
  letter = "letter",
}

export const DIGITAL_VAULT_MAPPINGS = [MappingTypes.app_field]
export const FORM_FILLER_MAPPINGS = [MappingTypes.file_pdf, MappingTypes.letter]
export const ALL_MAPPINGS = [MappingTypes.app_field, MappingTypes.file_pdf, MappingTypes.letter]

export const useMappedAppFieldKeyStore = defineStore(
  /*<string, IState, _GettersTree<IState>, object | IActions>*/ "mapped-app-field-key",
  {
    state: (): IState => ({
      isLoaded: false,
      _caseAgnosticKeySignaturesByIndexOfCaseTaskIdAndMappingType: {},
    }),

    getters: {
      keysByCaseTaskIdAndMappingType(): Record<
        keyof IState["_caseAgnosticKeySignaturesByIndexOfCaseTaskIdAndMappingType"],
        TAppFieldValueKey[]
      > {
        return chain(this._caseAgnosticKeySignaturesByIndexOfCaseTaskIdAndMappingType)
          .mapValues(normalizePathsToSignatures)
          .mapValues(normalizeFormFillerKeySignatures)
          .mapValues(convertSignaturesToOrderedKeys)
          .value()
      },

      keySetsByCaseTaskIdAndMappingType(): Record<
        keyof IState["_caseAgnosticKeySignaturesByIndexOfCaseTaskIdAndMappingType"],
        ICompositeAppFieldKeySet["keySets"]
      > {
        return chain(this.keysByCaseTaskIdAndMappingType)
          .mapValues(keySets => values(groupAppFieldKeysIntoKeySets(keySets)))
          .mapValues(bundleRepeatedKeysInKeySets)
          .value()
      },
    },

    actions: {
      async pullForCaseWith(caseId: ICase["id"]) {
        // todo: update this to declare dependencies
        const [{data}] = await Promise.all([
          axios.get<{
            data: Record<ICaseTask["id"], ISignaturesByMappingType>
          }>(`/v3/enduser/cases/${caseId}/mapped-data-model-paths`),
          // needed by `normalizeFormFillerKeySignatures` below, but async gets noisy further down the chain
          useCaseQuestionnaireSessionProgressStore().pull(true),
        ])

        this._caseAgnosticKeySignaturesByIndexOfCaseTaskIdAndMappingType = flattenNestedMappingsToTopLevelIndex(
          data.data,
        )
        this.isLoaded = true
      },

      hasKeysForCaseTaskIdAndMappingType(
        caseTaskId: ICaseTask["id"],
        type: keyof ISignaturesByMappingType | (keyof ISignaturesByMappingType)[],
      ) {
        return !!this.getKeysByCaseTaskIdAndMappingType(caseTaskId, type).length
      },

      getKeysByCaseTaskIdAndMappingType(
        caseTaskId: ICaseTask["id"],
        type: keyof ISignaturesByMappingType | (keyof ISignaturesByMappingType)[],
      ) {
        return mergeListsOfKeys(
          (isArray(type) ? type : [type]).map(t => this.keysByCaseTaskIdAndMappingType[`${caseTaskId}/${t}`] || []),
        )
      },

      getKeySetsByCaseTaskIdAndMappingType(
        caseTaskId: ICaseTask["id"],
        type: keyof ISignaturesByMappingType | (keyof ISignaturesByMappingType)[],
      ) {
        return mergeDuplicateKeySets(
          flatMap(
            isArray(type) ? type : [type],
            t => this.keySetsByCaseTaskIdAndMappingType[`${caseTaskId}/${t}`] || [],
          ),
        )
      },

      isKeyOfMappingTypeFor(
        caseTaskId: ICaseTask["id"],
        key: TAppFieldValueKey,
        type: keyof ISignaturesByMappingType | (keyof ISignaturesByMappingType)[],
      ) {
        return !!find(this.getKeysByCaseTaskIdAndMappingType(caseTaskId, type), {model: {path: key.model.path}})
      },
    },
  },
)

export function flattenNestedMappingsToTopLevelIndex(
  pathsOrSignaturesByCaseTaskIdAndMappingType: Record<ICaseTask["id"], ISignaturesByMappingType>,
) {
  return transform(
    pathsOrSignaturesByCaseTaskIdAndMappingType,
    (accumulator: Record<string, TAppFieldValueKey["signature"][]>, sigsByMappingType, caseTaskId) =>
      mapValues(sigsByMappingType, (signatures, mappingType: keyof ISignaturesByMappingType) => {
        accumulator[`${caseTaskId}/${mappingType}`] = signatures
      }),
    {},
  )
}

export function normalizePathsToSignatures(
  pathsOrSignatures: TAppFieldValueKey["model"]["path"][] | TAppFieldValueKey["signature"][],
): TAppFieldValueKey["signature"][] {
  return generateRepeatedKeySignaturesFor(pathsOrSignatures)
}

export function normalizeFormFillerKeySignatures(signatures: null | TAppFieldValueKey["signature"][]) {
  const validSignatures = filter(signatures, signature => isValidFormFillerPath(convertKeySignatureToPath(signature)))

  // generate corresponding child.name key signatures for child fields to ensure we always collect name
  const additionalChildrenNameSignatures = chain(validSignatures)
    .map(createKeyFromSignature)
    .filter(({model: {path}}) => startsWith(path, "children[*]"))
    .map(({indices}) => `children[${indices![0]}].name`)
    .uniq()
    .difference(validSignatures)
    .value()

  const interestingRelatedAddressSignatures = [
    "deceased.mailing_address",
    "deceased.is_mailing_address_same_as_home_address",
  ]
  const additionalDeceasedAddressSignatures = intersection(signatures, interestingRelatedAddressSignatures).length
    ? interestingRelatedAddressSignatures.concat("deceased.home_address")
    : []

  return uniq(validSignatures.concat(additionalChildrenNameSignatures).concat(additionalDeceasedAddressSignatures))
}

export function isValidFormFillerPath(path: IAppField["path"]) {
  const {appFieldsByPath} = useAppFieldStore()
  const {blocksByAppFieldSignature} = useCaseQuestionnaireSessionProgressStore()
  const questionnaireBlockPaths = chain(blocksByAppFieldSignature).keys().map(convertKeySignatureToPath).value()
  const irrelevantPathsForCaseState = useCaseStore().isActiveCasePreplanner
    ? [...DECEASED_END_OF_LIFE_INFO_PATHSET.paths]
    : []
  const irrelevantPathsForAbilities = useCaseMembersStore().isAuthMemberASuccessorLimitedAccess
    ? [WILL_ATTACHMENT_PATH]
    : []

  const blacklistedPaths = ["date.today", "estate_reps[*].date_of_birth", "estate_reps[*].social_insurance_number"]
    .concat(questionnaireBlockPaths)
    .concat(irrelevantPathsForCaseState)
    .concat(irrelevantPathsForAbilities)

  return path in appFieldsByPath && !includes(blacklistedPaths, path)
}

export function keysBySectionId(
  keys: TAppFieldValueKey[],
  pathsBySectionId: typeof PATHS_BY_SECTION_ID,
): Record<SectionTypes, TAppFieldValueKey[]> {
  return mapValues(pathsBySectionId, (paths: IAppField["path"][], section) =>
    keys.filter(({model: {path}}) => paths.includes(path)),
  )
}
