import * as Sentry from "@sentry/vue"
import type {CaptureContext, SeverityLevel} from "@sentry/types"
import {includes} from "lodash"

export type TMessageId = string

export function createLogger() {
  return Logger
}

export default class Logger {
  /** Debug level does not ship to Sentry; only info level and higher. */
  static debug(module: string, msg: string, context: CaptureContext = {}): null | TMessageId {
    return this._capture(msg, {level: "debug", tags: {module}, ...context})
  }

  static info(module: string, msg: string, context: CaptureContext = {}): null | TMessageId {
    return this._capture(msg, {level: "info", tags: {module}, ...context})
  }

  static log(module: string, msg: string, context: CaptureContext = {}): null | TMessageId {
    return this._capture(msg, {level: "log", tags: {module}, ...context})
  }

  static warn(module: string, msg: string, context: CaptureContext = {}): null | TMessageId {
    debugger
    return this._capture(msg, {level: "warning", tags: {module}, ...context})
  }

  static error(module: string, msg: string, context: CaptureContext = {}): null | TMessageId {
    debugger
    return this._capture(msg, {level: "error", tags: {module}, ...context})
  }

  // static fatal(module: string, msg: string, context: CaptureContext={}) {
  //     this._capture(msg, {level: 'fatal', tags: {module}, ...context})
  // }

  static _capture(msg: string, context: CaptureContext): null | TMessageId {
    // @ts-ignore
    if (process.env.NODE_ENV === "test" && !includes(["error", "fatal"] as SeverityLevel[], context.level)) {
      return null
    }

    const msgId = this._send(msg, context)
    // @ts-ignore
    console[context.level === "warning" ? "warn" : context.level](context.tags.module, msg, context)
    return msgId
  }

  static _send(msg: string, context: CaptureContext): null | TMessageId {
    // @ts-ignore
    return context.level !== "debug"
      ? // @ts-ignore
        Sentry.captureMessage([context.tags.module, msg].join(" | "), context)
      : null
  }
}
