import {chain, cloneDeep, each, every, find, isNil, map, some} from 'lodash'
import type {IBlock} from "../domain/questionnaires/block.interface"
import type {IPrompt} from "../domain/questionnaires/prompt.interface"
import type {ISelectOneConfig} from "../domain/questionnaires/prompt/select-one-prompt.interface"
import type {IQuestionnaire} from "../domain/questionnaires/questionnaire.interface"
import type {IPromptChangedEvent} from "@/library/domain/questionnaires/prompt-changed.interface"
import useActiveCase from "@/library/composables/useActiveCase"
import {defineStore} from "pinia"
import {findAppFieldBySignature, useAppFieldValueStore} from "@/library/stores/app-field-value"
import {useCaseQuestionnaireSessionStore} from "@/library/stores/case-questionnaire-session"
import {useNotificationsStore} from "@/library/stores/notifications"
import useErrorHandler from "@/library/composables/useErrorHandler"
import {createLogger} from "@/library/domain/logger"
import type {IQSessionWithProgress} from "@/library/domain/questionnaires/q-engine"
import {hydrateFrom} from "@/library/domain/questionnaires/q-engine"
import type {IFlowSession} from "@/library/domain/questionnaires/flow-session"
import {
  calculateCompletionAsPercentage,
  generateFlowUUIDToPromptsWithSkipsMap,
} from "@/library/domain/questionnaires/flow-session"
import {getAppFieldSignaturesFor, useQuestionnaireStore} from "@/library/stores/questionnaire"
import type {ICaseQuestionnaireSession} from "@/library/models/case-questionnaire-session.interface"
import {useCaseStore} from "@/library/stores/case"
import {useCaseMembersStore} from "@/library/stores/case-member"
import type {IValidityObserverStore} from "@/form-validator/stores/validity-observer-store"
import i18next from "i18next"

// todo: move elsewhere: flow-session?
export enum FlowSubmissionTypes {
  PER_BLOCK = "per_block",
  PER_FLOW = "per_flow",
}

export interface IBasicQSessionWithProgress {
  questionnaireId: IQuestionnaire["id"]

  // Ordered questionnaire progress
  // todo: should this be renamed to flowProgress| ?
  flowSessions: IFlowSession[] // non-nullable; maintain ref to facilitate set manipulation over named computed prop
}

interface IState {
  basicQuestionnaireSessionsWithProgress: IBasicQSessionWithProgress[]
  // supports changing an answer then back again; global history
  historicalPromptValuesByAppFieldSignature: Record<IBlock["app_field_signature"], IPrompt["response"]["value"]>

  /** @deprecated */
  loadingTaskPlanner: Promise<IBasicQSessionWithProgress> | null
  loadingPreNeed: Promise<IBasicQSessionWithProgress> | null
}

export const useCaseQuestionnaireSessionProgressStore = defineStore("case-questionnaire-session-progress", {
  state: (): IState => ({
    // todo: convert this to baseCollectionStore
    basicQuestionnaireSessionsWithProgress: [],
    historicalPromptValuesByAppFieldSignature: {},

    /** @deprecated */
    loadingTaskPlanner: null,
    loadingPreNeed: null,
  }),

  getters: {
    // todo: aggregate over `task_planner` and `pre_need`
    blocksByAppFieldSignature(state): Record<IBlock["app_field_signature"], IBlock> {
      return chain(this.taskPlanner?.questionnaire.flows).flatMap("blocks").keyBy("app_field_signature").value()
    },

    isLoaded(state): boolean {
      // since we don't fetch any dependencies for successor
      return (
        useCaseMembersStore().isAuthMemberASuccessor ||
        (this.taskPlanner !== null && (this.shouldShowPreNeed ? this.preNeed !== null : true))
      )
    },

    questionnaireSessionsWithProgress(): IQSessionWithProgress[] {
      const supportedQuestionnaires = [useQuestionnaireStore().taskPlanner, useQuestionnaireStore().preNeed]
      // @ts-ignore - TS can't figure out the trailing filter() used to omit nulls
      return chain(supportedQuestionnaires)
        .filter() // omit questionnaires not yet fetched
        .map(questionnaire => {
          const plannerSessionProgress = find(this.basicQuestionnaireSessionsWithProgress, {
            questionnaireId: questionnaire.id,
          })
          if (!plannerSessionProgress) {
            return null
          }

          return inflateQSessionWithProgressFrom(plannerSessionProgress as IBasicQSessionWithProgress, questionnaire)
        })
        .filter() // omit sessions not yet initialized/hydrated
        .value()
    },

    taskPlanner(): IQSessionWithProgress | null {
      const id = useQuestionnaireStore().taskPlanner?.id
      return find(this.questionnaireSessionsWithProgress, {questionnaire: {id}}) || null
    },

    isTaskPlannerFinished(): boolean {
      return !!this.taskPlanner?.session.completed_at
    },

    shouldShowPreNeed: () => useCaseStore().isActiveCaseAfterCare && !!useQuestionnaireStore().preNeedUuid,

    preNeed(): IQSessionWithProgress | null {
      const id = useQuestionnaireStore().preNeed?.id
      return find(this.questionnaireSessionsWithProgress, {questionnaire: {id}}) || null
    },
  },

  actions: {
    async pull(useCache = true) {
      const caseMembersStore = useCaseMembersStore()

      if (caseMembersStore.isAuthMemberASuccessor && !caseMembersStore.isAuthMemberASuccessorCollaborator) {
        createLogger().debug(
          "client/enduser/src/library/stores/case-questionnaire-session-progress.ts",
          `avoid fetching questionnaires + sessions for successors
                     the caveat is that we leverage questionnaires to blacklist questionnaire app field 
                     signatures on form filler via \`isValidFormFillerPath()\` -- relying on the fact 
                     that a successor is a read-only type of experience BUT it doesn't change that in
                      the future, if we were to embed form-filler app field signatures into a 
                      questionnaire, they'd NOT be blacklisted on successor accounts due to this bail`,
        )
        return
      }

      const requisites = [
        this.pullTaskPlannerProgress(useCache),
        ...(this.shouldShowPreNeed ? [this.pullPreNeedProgress(useCache)] : []),
      ]

      await Promise.all(requisites)
    },

    async pullTaskPlannerProgress(useCache = true): Promise<IQSessionWithProgress> {
      if (!this.loadingTaskPlanner) {
        this.loadingTaskPlanner = this.fetchAndHydrateFor(useQuestionnaireStore().taskPlannerUuid)
      }

      await this.loadingTaskPlanner
      return this.taskPlanner!
    },

    async pullPreNeedProgress(useCache = true) {
      if (!this.loadingPreNeed) {
        this.loadingPreNeed = this.fetchAndHydrateFor(useQuestionnaireStore().preNeedUuid)
      }

      await this.loadingPreNeed
      return this.preNeed!
    },

    async fetchAndHydrateFor(questionnaireUuid: IQuestionnaire["uuid"]) {
      // fetch
      const appFieldValueStore = useAppFieldValueStore()
      const qSessionStore = useCaseQuestionnaireSessionStore()
      const questionnaireStore = useQuestionnaireStore()
      const caseId = useActiveCase().id

      // fetch data dependencies
      const {questionnaire_id: questionnaireId} =
        (await qSessionStore.pull(caseId, questionnaireUuid)) ?? (await qSessionStore.create(caseId, questionnaireUuid))
      const questionnaire = await questionnaireStore.pullById(questionnaireId)

      await Promise.all([this.patchAppFieldValueOptionsFrom(questionnaire), appFieldValueStore.pullAppFieldValues()])

      if (questionnaire) {
        for (const signature of getAppFieldSignaturesFor(questionnaire)) {
          const valueForSignature = appFieldValueStore.getAppFieldValuesForSignature(signature)
          if (! isNil(valueForSignature)) {
            this.historicalPromptValuesByAppFieldSignature[signature] = valueForSignature
          }
        }
      }

      // hydrate and replay flow sessions
      const length = this.basicQuestionnaireSessionsWithProgress.push({
        questionnaireId,
        flowSessions: hydrateFrom(
          questionnaire!.flows,
          questionnaireId,
        ),
      })

      return this.basicQuestionnaireSessionsWithProgress[length - 1]
    },

    async patchAppFieldValueOptionsFrom(q: IQuestionnaire | null) {
      each(q?.flows, f => {
        // casting as select-* blocks to get choices out, though it may be absent on non-select-* blocks
        // blank choices is handled below in appField.value_options mapping
        each(f.blocks as IBlock<ISelectOneConfig>[], ({app_field_signature, config: {choices}}) => {
          const appField = findAppFieldBySignature(app_field_signature)
          if (!appField) {
            createLogger().error(
              "executor-assistant/task-planner/stores/questionnaire-session",
              "Unable to find app field for block",
              {extra: {app_field_signature}},
            )
            return undefined
          }

          // override app field's choices from `block.config`; choices are empty on input fields (eg. email)
          appField.value_options = (choices || []).map(({value, is_exclusive}) => {
            const label = i18next.t(
              `questionnaire_${q!.uuid}:flow.${f.uuid}.blocks.${app_field_signature}.body.choices.${value}`,
            )
            return {label, value, is_exclusive}
          })
        })
      })
    },

    async pushValuesFrom(
      form: IValidityObserverStore<any>,
      {flowSession, discarded}: IPromptChangedEvent<IPrompt>,
      sessionId: ICaseQuestionnaireSession["id"],
    ) {
      // synchronize prompt changes back up to `form`

      // take into account replay by restoring values yanked from historicalPromptValues index and reset form state
      each(flowSession.prompts, ({block: {app_field_signature}, response: {value}}) => {
        const validity = form.get(app_field_signature).value
        // don't allow restore to overwrite draft changes
        validity.reset(validity.isPristine ? value : validity.raw)
      })

      // todo: let's start writing regression tests for this insanity.

      // void discarded responses and reset form state
      each(discarded, ({block: {app_field_signature}}) => {
        form.get(app_field_signature).value.reset(undefined)
      })

      const dataset = cloneDeep(form.boundData()) // note: this provides the ability to submit invalid data
      const deleted = map(discarded, "block.app_field_signature")

      try {
        await useAppFieldValueStore().pushAppFieldValues(dataset, deleted, sessionId, false)
        useNotificationsStore().success(i18next.t("form.submit_success"), {auto_close: 1000})
      } catch (e: any) {
        createLogger().error(
          "executor-assistant/task-planner/stores/questionnaire-session",
          "Unable to persist questionnaire app fields",
          {extra: {e}},
        )
        useErrorHandler().handle(e)
      }
    },

    // todo: push content helpers out to something like questionnaire store
    getBlockContentFor(appFieldSignature: string, flowUuid: string, qUuid: string): IBlockContent {
      return i18next.t(
        `questionnaire_${qUuid}:flow.${flowUuid}.blocks.${appFieldSignature}`,
      ) as unknown as IBlockContent
    },

    getFlowTitleFor(flowUuid: IFlowSession["flow_uuid"], questionnaireUuid: IQuestionnaire["uuid"]) {
      return i18next.t(`questionnaire_${questionnaireUuid}:flow.${flowUuid}.title`)
    },
  },
})

export function inflateQSessionWithProgressFrom(
  {flowSessions, questionnaireId}: IBasicQSessionWithProgress,
  questionnaire: IQuestionnaire,
): IQSessionWithProgress {
  const session = useCaseQuestionnaireSessionStore().latestSessionByQuestionnaireId[questionnaire.id]
  const flowUUIDToPromptsWithSkipsMap = generateFlowUUIDToPromptsWithSkipsMap(flowSessions)
  const progress = calculateCompletionAsPercentage(flowSessions, questionnaire.flows)
  return {
    questionnaireId,
    questionnaire,
    session,

    flowSessions,

    progress,
    wasStarted: progress !== 0,
    // considered complete when every flow has engagement, and not currently prompting for additional info
    isComplete: flowSessions.length === questionnaire.flows.length && every(flowSessions, ({draft}) => !draft),

    flowUUIDToPromptsWithSkipsMap,
    hasSkipsOnRequired: some(flowUUIDToPromptsWithSkipsMap, "length"),
  }
}

export interface IBlockContent {
  header: {
    title?: string
    subtitle?: string
  }
  body: {
    title?: string
    text?: string
    choices: object
  }
}
