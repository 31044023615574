<template>
  <div v-if="progress" class="pending-request-progress">
    <div
        class="pending-request-progress-bar"
        role="progressbar"
        :style="{width: `${progress}%`}"
        :aria-valuenow="progress"
        aria-valuemin="0"
        aria-valuemax="100"></div>
  </div>
</template>

<script setup lang="ts">
import {usePendingRequestsStore} from "@/library/stores/pending-requests"
import {computed, watch} from "vue"
import {createLogger} from "@/library/domain/logger";

const pendingRequestsStore = usePendingRequestsStore()
const progress = computed(() => pendingRequestsStore.progressPercentage)
watch(
    () => pendingRequestsStore.hasPendingRequests,
    hasPendingRequests => {
      // when we have pending requests: disable all buttons that do not have .form-elements-no-disable
      // and are not within parent elements with .form-elements-no-disable.
      //
      // this code works in conjunction with the disabled styling that gets applied to the button via .form-elements-disabled
      // but takes it a step further by preventing keyboard 'enter' hits from triggering the buttons 'submit' action.
      if (hasPendingRequests) {
        let buttons: NodeListOf<HTMLButtonElement>
        try {
          // querySelectorAll will fail if the browser does not support :not, which is why the try-catch is necessary
          buttons = document.querySelectorAll("button:not(.form-elements-no-disable):not(.form-elements-no-disable button):not(.form-elements-was-disabled):not(:disabled)")
        } catch (e) {
          createLogger().log("library/components/layout/PendingRequestLoader", "Bailing due to unsupported :not selector")
          return
        }

        for (const button of buttons) {
          button.classList.add("form-elements-was-disabled") // mark as touched
          button.disabled = true
        }
      } else {
        // when we no longer have pending requests, re-enable previously disabled buttons
        const buttons: NodeListOf<HTMLButtonElement> = document.querySelectorAll("button.form-elements-was-disabled")
        for (const button of buttons) {
          button.classList.remove("form-elements-was-disabled") // mark untouched
          button.disabled = false
        }
      }
    }
)
</script>

<style scoped lang="scss">
.pending-request-progress {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 4px;
  z-index: 1080;
}

.pending-request-progress-bar {
  background: var(--cadence-tertiary);
  opacity: 0.8;
  transition: width 0.35s ease;
}
</style>
