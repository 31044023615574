import {
  type IRepeatableAppFieldPathSets,
  type IRepeatableAppFieldPathSetsWithConstraints,
} from "@/library/domain/app-fields/key-sets"
import {SectionTypes} from "@/library/domain/app-fields/key-sets/sections"

export const FINANCES_PATH = "financial_information"

export const FINANCES_PATHSET: IRepeatableAppFieldPathSets = {
  id: SectionTypes.FINANCIAL_INFORMATION,
  repeatable_by: "financial_information[*]",
  paths: [
    "financial_information[*].type",
    "financial_information[*].name",
    "financial_information[*].expiry_date",
    "financial_information[*].id",
    "financial_information[*].attachment",
    "financial_information[*].notes",
  ],
}

export const FILTER_FINANCIAL_INFORMATION__BANK_FINANCIAL_SERVICES_PROVIDER_PATHSET: IRepeatableAppFieldPathSetsWithConstraints =
  {
    id: SectionTypes.FILTER_FINANCIAL_INFORMATION__bank_financial_services_provider,
    repeatable_by: "financial_information__bank_financial_services_provider[*]",
    constrained_by: null,
    source: FINANCES_PATHSET,
  }
export const FILTER_FINANCIAL_INFORMATION__CREDIT_CARD_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_FINANCIAL_INFORMATION__credit_card,
  repeatable_by: "financial_information__credit_card[*]",
  constrained_by: null,
  source: FINANCES_PATHSET,
}
export const FILTER_FINANCIAL_INFORMATION__INVESTMENT_PROVIDER_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_FINANCIAL_INFORMATION__investment_provider,
  repeatable_by: "financial_information__investment_provider[*]",
  constrained_by: null,
  source: FINANCES_PATHSET,
}
export const FILTER_FINANCIAL_INFORMATION__LEASE_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_FINANCIAL_INFORMATION__lease,
  repeatable_by: "financial_information__lease[*]",
  constrained_by: null,
  source: FINANCES_PATHSET,
}
export const FILTER_FINANCIAL_INFORMATION__LOAN_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_FINANCIAL_INFORMATION__loan,
  repeatable_by: "financial_information__loan[*]",
  constrained_by: null,
  source: FINANCES_PATHSET,
}
export const FILTER_FINANCIAL_INFORMATION__PENSION_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_FINANCIAL_INFORMATION__pension,
  repeatable_by: "financial_information__pension[*]",
  constrained_by: null,
  source: FINANCES_PATHSET,
}
export const FILTER_FINANCIAL_INFORMATION__PHILANTHROPY_CHARITY_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_FINANCIAL_INFORMATION__philanthropy_charity,
  repeatable_by: "financial_information__philanthropy_charity[*]",
  constrained_by: null,
  source: FINANCES_PATHSET,
}
