import type {IAppField, ITagConstraint, IValueOption} from "@/library/models/app-fields/app-field.interface"
import {chain} from "lodash"

export interface ITagProvision {
  source: {
    app_field_path: IAppField["path"]
    value_option: IValueOption["value"]
    provides_tags: ITagConstraint["value"]
  }
  target: {
    app_field_path: IAppField["path"]
    value_options: IValueOption["value"][]
  }
}

export type ITargetlessTagProvision = Omit<ITagProvision, "target">

export const TAG_PROVISIONS: (ITagProvision | ITargetlessTagProvision)[] = [
  {
    source: {
      app_field_path: "account[*].type",
      value_option: "digital_account",
      provides_tags: ["digital_vault"],
    },
    target: {
      app_field_path: "account[*].name",
      value_options: [
        "amazon",
        "amazon_prime",
        "apple",
        "apple_music",
        "apple_tv",
        "crave",
        "disney_plus",
        "ebay",
        "espn_plus",
        "facebook",
        "flickr",
        "google",
        "hbo_max",
        "hulu",
        "instagram",
        "linkedin",
        "netflix",
        "paramount_plus",
        "paypal",
        "pinterest",
        "sling_tv",
        "snapchat",
        "spotify",
        "twitter",
        "whatsapp",
        "yahoo",
        "youtube",
        "youtube_tv"
      ],
    },
  },
  {
    source: {
      app_field_path: "account[*].type",
      value_option: "loyalty_rewards_program",
      provides_tags: ['membership'],
    },
    target: {
      app_field_path: "account[*].name",
      value_options: [
        "aeroplan",
        "airmiles",
        "alaska_airlines",
        "american_airlines",
        "american_express",
        "aventura",
        "caa",
        "canadian_tire",
        "capital_one",
        "chase_ultimate",
        "city_thank_you",
        "coop",
        "costco",
        "delta_sky_miles",
        "hbc_rewards",
        "hilton_honors",
        "jet_blue",
        "marriot_ronboy",
        "my_best_buy",
        "pc_optimum",
        "scene",
        "shell_go_plus",
        "southwest",
        "united",
        "wells_fargo",
        "westjet",
        "world_of_hyatt",
      ],
    },
  },
  ...["computer_login", "crypto_wallet"].map(value_option => ({
    source: {
      app_field_path: "account[*].type",
      value_option,
      provides_tags: [],
    },
  })),
]

export function discoverTagsFor(
  appField: IAppField,
  value: IValueOption["value"],
  providerTagsIndex = generateIndexForTagsBySourcePathAndValueOption(),
  targetTagsIndex = generateIndexForTagsByTargetPathAndValueOption(),
) {
  const key = buildKeyFor(appField.path, value)
  const tags = (providerTagsIndex[key] || []).concat(targetTagsIndex[key] || [])

  // empty list == constrained to none
  // null == not constrained
  return key in providerTagsIndex || key in targetTagsIndex ? tags : null
}

export function generateIndexForTagsBySourcePathAndValueOption() {
  return chain(TAG_PROVISIONS)
    .keyBy(({source: {app_field_path, value_option}}) => buildKeyFor(app_field_path, value_option))
    .mapValues("source.provides_tags")
    .value()
}

export function generateIndexForTagsByTargetPathAndValueOption() {
  return chain(TAG_PROVISIONS as ITagProvision[])
    .reject(({target}) => !target)
    .map(({source: {provides_tags}, target: {app_field_path, value_options}}) =>
      value_options.map(value_option => [buildKeyFor(app_field_path, value_option), provides_tags]),
    )
    .flatten()
    .fromPairs()
    .value()
}

export function buildKeyFor(path: IAppField["path"], option: IValueOption["value"]) {
  return `${path}@${option}`
}
