import {
  type IRepeatableAppFieldPathSets,
  type IRepeatableAppFieldPathSetsWithConstraints,
} from "@/library/domain/app-fields/key-sets"
import {SectionTypes} from "@/library/domain/app-fields/key-sets/sections"

export const FARM_DOCUMENTS_PATH = "farm_documents"

export const FARM_DOCUMENTS_PATHSET: IRepeatableAppFieldPathSets = {
  id: SectionTypes.FARM_DOCUMENTS,
  repeatable_by: "farm_documents[*]",
  paths: [
    "farm_documents[*].type",
    "farm_documents[*].farm_name",
    "farm_documents[*].name",
    "farm_documents[*].expiry_date",
    "farm_documents[*].location_hint",
    "farm_documents[*].date_created",
    "farm_documents[*].last_updated",
    "farm_documents[*].attachment",
    "farm_documents[*].notes",
  ],
}

export const FILTER_FARM_DOCUMENTS__OPERATING_AGREEMENT_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_FARM_DOCUMENTS__operating_agreement,
  repeatable_by: "farm_documents__operating_agreement[*]",
  constrained_by: null,
  source: FARM_DOCUMENTS_PATHSET,
}
export const FILTER_FARM_DOCUMENTS__LAND_TITLE_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_FARM_DOCUMENTS__land_title,
  repeatable_by: "farm_documents__land_title[*]",
  constrained_by: null,
  source: FARM_DOCUMENTS_PATHSET,
}
export const FILTER_FARM_DOCUMENTS__EQUIPMENT_TITLE_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_FARM_DOCUMENTS__equipment_title,
  repeatable_by: "farm_documents__equipment_title[*]",
  constrained_by: null,
  source: FARM_DOCUMENTS_PATHSET,
}
export const FILTER_FARM_DOCUMENTS__INSURANCE_POLICY_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_FARM_DOCUMENTS__insurance_policy,
  repeatable_by: "farm_documents__insurance_policy[*]",
  constrained_by: null,
  source: FARM_DOCUMENTS_PATHSET,
}
export const FILTER_FARM_DOCUMENTS__LEASE_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_FARM_DOCUMENTS__lease,
  repeatable_by: "farm_documents__lease[*]",
  constrained_by: null,
  source: FARM_DOCUMENTS_PATHSET,
}
export const FILTER_FARM_DOCUMENTS__WATER_RIGHTS_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_FARM_DOCUMENTS__water_rights,
  repeatable_by: "farm_documents__water_rights[*]",
  constrained_by: null,
  source: FARM_DOCUMENTS_PATHSET,
}
export const FILTER_FARM_DOCUMENTS__CONSERVATION_PROGRAM_CONTRACT_PATHSET: IRepeatableAppFieldPathSetsWithConstraints =
  {
    id: SectionTypes.FILTER_FARM_DOCUMENTS__conservation_program_contract,
    repeatable_by: "farm_documents__conservation_program_contract[*]",
    constrained_by: null,
    source: FARM_DOCUMENTS_PATHSET,
  }
export const FILTER_FARM_DOCUMENTS__ENVIRONMENTAL_COMPLIANCE_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_FARM_DOCUMENTS__environmental_compliance,
  repeatable_by: "farm_documents__environmental_compliance[*]",
  constrained_by: null,
  source: FARM_DOCUMENTS_PATHSET,
}
export const FILTER_FARM_DOCUMENTS__FINANCIAL_STATEMENT_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_FARM_DOCUMENTS__financial_statement,
  repeatable_by: "farm_documents__financial_statement[*]",
  constrained_by: null,
  source: FARM_DOCUMENTS_PATHSET,
}
export const FILTER_FARM_DOCUMENTS__TAX_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_FARM_DOCUMENTS__tax,
  repeatable_by: "farm_documents__tax[*]",
  constrained_by: null,
  source: FARM_DOCUMENTS_PATHSET,
}
export const FILTER_FARM_DOCUMENTS__AGRICULTURAL_LOAN_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_FARM_DOCUMENTS__agricultural_loan,
  repeatable_by: "farm_documents__agricultural_loan[*]",
  constrained_by: null,
  source: FARM_DOCUMENTS_PATHSET,
}
export const FILTER_FARM_DOCUMENTS__TRANSFER_ON_DEATH_DEED_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_FARM_DOCUMENTS__transfer_on_death_deed,
  repeatable_by: "farm_documents__transfer_on_death_deed[*]",
  constrained_by: null,
  source: FARM_DOCUMENTS_PATHSET,
}
export const FILTER_FARM_DOCUMENTS__TRUST_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_FARM_DOCUMENTS__trust,
  repeatable_by: "farm_documents__trust[*]",
  constrained_by: null,
  source: FARM_DOCUMENTS_PATHSET,
}
export const FILTER_FARM_DOCUMENTS__ESTATE_PLAN_INTEGRATION_PATHSET: IRepeatableAppFieldPathSetsWithConstraints = {
  id: SectionTypes.FILTER_FARM_DOCUMENTS__estate_plan_integration,
  repeatable_by: "farm_documents__estate_plan_integration[*]",
  constrained_by: null,
  source: FARM_DOCUMENTS_PATHSET,
}
