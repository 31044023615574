import {defineStore} from "pinia"
import {compact, defer, delay} from "lodash"
import {useCaseStore} from "@/library/stores/case"
import {useCaseMembersStore} from "@/library/stores/case-member"
import {nextTick, watch} from "vue"
import {useUserStore} from "@/library/stores/user"
import {CaseStatus, NormalizedCaseStatus} from "@/library/models/case.interface"

export interface IUserMetricsDataPointContext {
  user_is_authenticated: string
  user_id: string // reserved on some platforms (eg. gtag)
  internal_user_id: string
  user_role: string
  user_role_id: string
}

export interface ICaseMemberMetricsDataPointContext {
  member_role: string
}

export interface ICaseMetricsDataPointContext {
  case_id: string
  case_status: string
  case_status_normalized: string

  case_org_id: string
  case_org_country_code: string

  case_location_id: string
  case_enterprise_id: string
  case_enterprise_division_id: string

  case_is_test: "true" | "false"
  case_is_transitioned: string
}

export enum MetricsFeature {
  PRE_NEED_LEAD_GEN = "pre_need_lead_gen",
  GRIEF_CARE = "grief_care",
  GRIEF_GROUP = "grief-group",
  EXECUTOR_ASSISTANT = "executor_assistant",
  EASY_NOTICE = "easynotice",
  LEGACY_PLANNER = "legacy_planner",
  COLLABORATORS = "collaborators",
  INVITATIONS = "invitations",
  BUSINESS_REVIEWS = "business_reviews",
  // ANALYTICS_FOR_BUSINESS = "analytics for business",
  // ANALYTICS_FOR_CADENCE = "analytics for cadence",
  CO_BRANDING = "co_branding",
  TASKS = "tasks",
  QUESTIONNAIRE = "questionnaire",
  DIGITAL_VAULT = "digital_vault",

  // Absent from Product Roadmap:
  DASHBOARD = "dashboard",
  SUPPORT = "support",
  TOOLS = "tools",
}

export interface IMetric {
  feature: MetricsFeature
  version: string // feature version
  story: string
  activity: string
}

export function createMx(
  feature: IMetric["feature"],
  story: IMetric["story"],
  activity: IMetric["activity"],
  version: IMetric["version"] = "1",
): IMetric {
  return {feature, version, story, activity}
}

export interface IMetricsDataPointContext
  extends IUserMetricsDataPointContext,
    ICaseMemberMetricsDataPointContext,
    ICaseMetricsDataPointContext {}

export const useMetricsDataPointStore = defineStore("metrics-data-point", {
  state: () => ({
    _isLoaded: false,
  }),
  getters: {
    hasPopulatedContext() {
      return useUserStore().user && useCaseStore().isLoaded && useCaseMembersStore().isLoaded
    },

    context(): IMetricsDataPointContext {
      return {
        ...createUserDataPointContext(),
        ...createCaseDataPointContext(),
        ...createCaseMemberDataPointcontext(),
      }
    },
  },

  actions: {
    async pull(): Promise<void> {
      this._isLoaded = false

      if (!useUserStore().getToken()) {
        this._isLoaded = true
        return
      }

      await Promise.all([
        // useUserStore().fetchAuthUser(), // implicitly resolved; cases and members rely on user being authed
        useCaseStore().itemsFetchPromise,
        useCaseMembersStore().itemsFetchPromise,
      ])

      await nextTick() // ensure computed values have had a chance to resolve

      return new Promise<void>(resolve => {
        delay(
          () => {
            this._isLoaded = true
            resolve()
          },
          2000, // brute force fallback for above nextTick() not waiting long enough for case and user observers
        )
      })
    },

    async isLoaded() {
      return new Promise<IMetricsDataPointContext>(resolve => {
        const stop = watch(
          () => this._isLoaded,
          isLoaded => {
            if (!isLoaded) {
              return
            }

            defer(() => stop())
            resolve(this.context)
          },
          {immediate: true},
        )
      })
    },

    // todo: map features/stories/actions to context builders below which take in an instance of a thing
    // (cadence|postmark|[integration]).[feature].[version].[story].[role].[action]
    // eg. cadence.dashboard.v2.roadmap_shortcut_via_progress.collaborator.navigate_to_task
    async track(metric: IMetric, context: any /*IMetricsActionContext*/) {
      // does this belong here?
      // gtag.track()
      // segment._track()
    },
  },
})

export function createUserDataPointContext(): IUserMetricsDataPointContext {
  const user = useUserStore().user
  return {
    user_is_authenticated: `${!!useUserStore().getToken()}`,
    // reserved keyword, leveraged natively by gtag but not exposed as a dimension
    user_id: `${user?.metrics_id || ""}`,
    user_role: `${user?.role || ""}`,
    user_role_id: `${user?.role?.id || ""}`,
    // allows us to import and segment in reports
    internal_user_id: `${user?.metrics_id || ""}`,
  }
}

export function createCaseDataPointContext(): ICaseMetricsDataPointContext {
  const activeCase = useCaseStore().activeCase
  return {
    case_id: `${activeCase?.id || ""}`,
    case_status: `${activeCase?.status || ""}`,
    case_status_normalized: `${NormalizedCaseStatus[activeCase?.status as CaseStatus] || ""}`,
    case_org_id: `${activeCase?.org_id || ""}`,
    case_org_country_code: `${activeCase?.org?.country_code || ""}`,
    case_location_id: `${activeCase?.location?.id || ""}`, // todo: verify this is defaulted // always populated
    case_enterprise_id: `${activeCase?.org?.enterprise_id || ""}`,
    case_enterprise_division_id: `${activeCase?.org?.enterprise_division_id || ""}`,
    case_is_test: `${!!activeCase?.is_test}`,
    case_is_transitioned: `${!!activeCase?.meta.transitioned_to_aftercare_at}`,
  }
}

export function createCaseMemberDataPointcontext(): ICaseMemberMetricsDataPointContext {
  const authUserMember = useCaseMembersStore().authUserMember
  return {
    // produces something like `successor/collaborator` for successors w/ full access
    member_role: compact([
      `${authUserMember?.role || ""}`,
      authUserMember?.meta.is_collaborator ? "collaborator" : null,
    ]).join("/"),
  }
}
